body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
  font-family: "Arial", sans-serif;
}

.error-content {
  text-align: center;
  max-width: 500px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-code {
  font-size: 72px;
  color: #ff6b6b;
  margin: 0;
}

.error-message {
  font-size: 36px;
  color: #333;
  margin: 10px 0;
}

.error-description {
  font-size: 18px;
  color: #666;
  margin: 20px 0;
}

.error-button {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-button:hover {
  background-color: #ff4a4a;
}

.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f8f8;
  font-family: "Arial", sans-serif;
}

.terms-title {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.terms-content {
  width: 80%;
  max-width: 800px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 70vh;
}

/* Custom scrollbar styling */
.terms-content::-webkit-scrollbar {
  width: 12px;
}

.terms-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.terms-content::-webkit-scrollbar-thumb {
  background-color: #ff6b6b;
  border-radius: 8px;
  border: 3px solid #f1f1f1;
}

.terms-content::-webkit-scrollbar-thumb:hover {
  background-color: #ff4a4a;
}

.terms-content h2 {
  font-size: 24px;
  color: #333;
  margin-top: 20px;
}

.terms-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin: 10px 0;
}

.terms-content ul {
  list-style-type: none;
  padding: 0;
}

.terms-content ul li {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin: 5px 0;
  padding-left: 1em;
  text-indent: -1em;
}

.terms-content ul li::before {
  content: "•";
  color: #ff6b6b;
  padding-right: 10px;
}
